import { useState } from 'react';
import NextImage from 'next/image';
import { useRouter } from 'next/router';
import {
  Flex,
  Text,
  Input,
  Button,
  Alert,
  AlertIcon,
  Image,
  Grid,
  GridItem,
  Box,
} from '@chakra-ui/react';

const illustrations = ['ar', 'creator', 'social', 'youtube'];

export default function Login() {
  const [loading, setIsLoading] = useState(false);
  const [licenseKey, setLicenseKey] = useState('');
  const [error, setError] = useState(null);
  const router = useRouter();

  const randomImage = illustrations[Math.floor(Math.random() * illustrations.length)];

  const handleLogin = () => {
    setIsLoading(true);
    setError(null);

    fetch(`/api/login?license_key=${licenseKey}`)
      .then((res) => res.json())
      .then(({ success }) => {
        if (!success) {
          setError('Invalid license key');
          return;
        }
        router.push('/');
      })
      .catch((err) => {
        console.log(err);
        setError('An error occurred while logging in');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Grid gridTemplateColumns="1fr 600px" width="full" minH="100vh">
      <GridItem bgColor="telegram.50">
        <Flex
          justifyContent="center"
          alignItems="center"
          width="full"
          minH="100vh"
          flexDir="column">
          <Box transform="translateY(-100px)">
            <NextImage
              key={1}
              src={`/imgs/illustrations/${randomImage}.png`}
              width={600}
              height={600}
              layout="intrinsic"
              quality={100}
            />
            <Text
              fontSize="5xl"
              fontWeight="bold"
              lineHeight={1}
              textAlign="center"
              mt={5}
              color="telegram.900">
              {' '}
              Your content deserves <br /> more audience{' '}
            </Text>
            <Text textAlign="center" mt={5}>
              Reach a larger public with Omnixent
            </Text>
          </Box>
        </Flex>
      </GridItem>
      <GridItem minH="100vh" width="full" bgColor="blue.500">
        <Flex
          p={10}
          maxW={500}
          minH="100vh"
          m="auto"
          justifyContent="center"
          alignItems="center"
          flexDir="column">
          <Image src={`/imgs/omx-logo-dark.svg`} width={300} mb={5} />
          <Text mt={4} mb={4} textAlign="center" color="gray.50">
            Insert your license key to start using <b>Omnixent</b>
          </Text>
          <Input
            placeholder="License key"
            colorScheme="facebook"
            variant="filled"
            _focus={{
              backgroundColor: 'gray.50',
            }}
            value={licenseKey}
            onChange={({ target }) => setLicenseKey(target.value)}
          />

          {error && (
            <Alert status="error" mt={4}>
              <AlertIcon />
              {error}
            </Alert>
          )}

          <Button
            colorScheme="facebook"
            variant="solid"
            isFullWidth
            mt={4}
            isLoading={loading}
            onClick={handleLogin}>
            Login
          </Button>
          <a href="https://gumroad.com/l/omnixent" target="_blank">
            <Button color="white" variant="link" isFullWidth mt={4} width={300}>
              I don't have a license key
            </Button>
          </a>
        </Flex>
      </GridItem>
    </Grid>
  );
}
